/*******
*
* CART LIST
*
*-------------------------------- CART LIST */
.cart-items {
}
.cart-item {
	$self: &;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: white;
	border-bottom: 1px solid #ccc;
	position: relative;
	@media screen and (max-width: 1198px) {
		flex-direction: column;
		align-items: stretch;
		padding: 0;
		margin-bottom: 2rem;
	}

	&__thumb img {
		width: 80px;
		height: 80px;
		display: inline-block;
	}

	@media screen and (min-width: 1199px) {
		&__thumb {
			padding: 1rem;
			flex: 0 1 100px;
		}
		&__description {
			flex: 0 1 250px;
		}

		&__availability {
			flex: 0 1 80px;
		}

		&__price {
			flex: 0 0 90px;
			width: 90px;
		}

		&__quantity {
			flex: 0 1 90px;
		}
	}

	&__title a {
		font-weight: 700;
		margin-bottom: 0.5rem;
		display: inline-block;
	}

	// title bar
	&--title-bar {
		background-color: $primary;
		color: white;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		line-height: 1;
		@media screen and (max-width: 1198px) {
			display: none;
		}
		#{$self}__thumb {
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}
}

.cart-item__desc,
.cart-item__desc p {
	font-size: 14px;
}

.cart-item-col {
	padding-left: 15px;
	padding-right: 15px;
	@media screen and (max-width: 1198px) {
		text-align: center;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-top: 1px solid #eee;
	}
}

// remove from cart
.remove-from-cart {
	position: absolute;
	bottom: 10px;
	right: 5px;
}

.icon-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	&--vert {
		background-color: #2ecc71;
	}
	&--bleu {
		background-color: #3498db;
	}

	&--orange {
		background-color: #e67e22;
	}
	&--rouge {
		background-color: #c0392b;
	}
}
// m1-toolobox
.m1-toolbox {
	.icon-questionmark {
		background-color: $primary;
		color: white;
		display: inline-block;
		width: 28px;
		height: 28px;
		vertical-align: middle;
		text-align: center;
		border-radius: 50%;
		font-style: normal;
		font-weight: 700;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.m1-toolbox-body {
		visibility: hidden;
		opacity: 0;
		background-color: white;
		padding: 1rem;
		border-radius: 5px;
		box-shadow: 0 0 5px #eee;
		font-size: 14px;
		position: absolute;
		z-index: 999;
	}

	&:hover {
		.m1-toolbox-body {
			visibility: visible;
			opacity: 1;
		}
	}
}

/*******
*
* CART SUMMARY
*
*-------------------------------- CART SUMMARY */
.cart-detailed-totals-m1 .cart-item-total {
	font-size: 24px;
	font-weight: 700;
}
.cart-detailed-totals-m1 table {
	background-color: white;
}
.cart-detailed-totals-m1 tbody tr td {
	border: 1px solid #eee;
	padding: 8px 10px;
}
.cart-detailed-totals-m1 tbody tr td:first-child {
	font-weight: 700;
}

/*******
*
* CART VOUCHER
*
*-------------------------------- CART VOUCHER */

.block-promo .promo-code {
	background-color: transparent;
}
.block-promo .promo-code-button {
	font-weight: 700;
	font-size: 20px;
}

.block-promo .cart-summary-line .code {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

/*******
*
* CART ACTIONS
*
*-------------------------------- CART ACTIONS */
.m1_quotation_btn {
	.btn {
		background-color: dimgray;
		width: 100%;
		text-align: center;
	}
}
