.btn-o.hvr-sweep-to-right, .hvr-sweep-to-right {
	background-color: $primary;
	color: white !important;
	padding: 0.625rem 2rem;
    z-index: 1;
	&::before {
		background-color: $bleu !important;
	}

    &:hover { 
        text-decoration: none !important;
    }
}

.kl-btn-compte.hvr-shutter-out-horizontal {
    text-decoration: none !important;
}
.btn-o.hvr-sweep-to-right, .hvr-sweep-to-right {
    background-color: $primary;
}


.btn-add-to-cart {
    background-color: $primary;
    border-radius: 0;
    padding-top: .8rem;
    padding-bottom: 0.8rem;
    i {
      content:'';
      width: 18px;
      height: 18px;
      margin-right: 12px;
      display: inline-block;
      background-color: red;
      vertical-align: middle;
      background: url("../img/icon-add-to-cart-mini.svg") no-repeat center center / contain;
    }
}