/******
*
*	PAYPAL BANNER
*
***********************/
.banner-paypal {
	// paypal blue color
	background-color: #003087;
	color: white;
	margin: 2rem auto;
	padding: 1rem;
	border-radius: 5px;
	text-align: center;
}
/******
*
*	PRODUCT THUMBNAIL
*
***********************/

.m1_thumbnails_product {
	display: flex;
	flex-wrap: wrap;
}

.m1_thumbnails_product .thumb-container {
	flex: 0 1 25%;

	@media screen and (min-width: 768px) {
		flex: 0 1 33.333333%;
	}

	@media screen and (min-width: 1140px) {
		flex: 0 1 100%;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

/******
*
*	PRODUCT REASSURANCE
*
***********************/
.m1-reassurance-product {
	margin-top: 1rem;
	margin-bottom: 1rem;
	@media screen and (min-width: 1024px) {
		display: flex;
		flex-wrap: wrap;
		margin-right: -16px;
		margin-top: 2rem;
	}
}
.m1-reassurance-product .m1rp-item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #e1e1e1;
	@media screen and (min-width: 1024px) {
		flex: 0 1 calc(50% - 8px);
		margin-right: 8px;
	}
	&__picto {
		flex: 0 0 40px;

		img {
			width: 100%;
			height: auto;
		}
	}
	&__text {
		padding-left: 1rem;
		word-wrap: break-word;
		flex: 0 1 calc(100% - 40px);

		@media screen and (max-width: 321px) {
			font-size: 0.8rem;
		}
	}

	&__text .legend {
		display: block;
		font-size: 0.8rem;
	}
}

/******
*
*	PRODUCT DETAILS
*
***********************/

.kl-details-product h1 {
	text-transform: uppercase;
	font-size: 20px;
	@media screen and (min-width: 768px) {
		font-size: 24px;
	}
}

.kl-details-product .product-reference {
	font-size: 16px;
	text-transform: uppercase;
	color: $primary-light;
	margin-bottom: 0.6rem;
}

/******
*
*	PRODUCT DETAILS
*
------------------------------------ SELECT WITH THUMBS */
$select-height: 70px;
.m1-select-with-thumb {
	height: $select-height;
	overflow: hidden;
	border: 1px solid #eee;
	position: relative;
	z-index: 10;
	transition: height 500ms ease;
	position: absolute;

	&::after {
		content: "";
		display: inline-block;
		height: 0;
		width: 0;
		border-top: 6px solid $primary-light;
		border-right: 4px solid transparent;
		border-left: 4px solid transparent;
		position: absolute;
		top: 20px;
		right: 1rem;
	}

	li {
		height: $select-height;
		display: flex;
		align-items: center;
		padding: 0rem;
		background-color: white;
		transition: opacity 500ms ease;
		padding: 8px 30px 8px 8px;
		outline: 1px solid #fff;
		cursor: pointer;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: $primary-dark;
			width: 4px;
			opacity: 0;
			visibility: hidden;
			transition: visibility 300ms ease, opacity 300ms ease;
		}

		&:not(:first-child):hover {
			&::before {
				opacity: 1;
				visibility: visible;
			}
		}

		.thumbnail {
			min-width: 50px;
			flex: 0 1 50px;
			height: $select-height - 20px;
			margin-bottom: 1px;
			margin-left: 4px;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.product-name {
			padding-left: 16px;
			flex: 0 1 100%;
			font-size: 14px;
			text-align: left;
			line-height: 1.1;
		}
	}
}

.sandbox {
	display: none;
}

/******
*
*	PRODUCT DETAILS
*
------------------------------------ INPUT QTY */

#product {
	.kl-header-product {
		> .container {
			@media screen and (min-width: 1400px) {
				max-width: 1285px;
			}
		}
	}

	.kl-title-section {
		font-size: 24px;
		text-align: center;
		font-weight: 500 !important;

		&.text-left {
			@media screen and (max-width: 768px) {
				text-align: center !important;
			}
		}

		&::before {
			content: "";
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 50px;
			height: 50px;
			background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;
			margin-bottom: 1rem;
		}

		@media screen and (min-width: 768px) {
			position: relative;
			display: table;
			margin-left: auto;
			margin-right: auto;
			padding-left: 70px;

			&::before {
				position: absolute;
				top: -10px;
				left: 0;
			}
		}

		@media screen and (min-width: 1180px) {
			padding-left: 60px;

			&::before {
				top: -10px;
			}

			&::after {
				transform: translateX(-28px);
			}
		}
	}

	.product-prices {
		font-size: 22px;
		font-weight: 500;
		.apartirde {
			font-size: 13px;
		}
	}

	/*******
	*
	* PRODUCT ADD TO CART
	*
	**************************/
	// qty with uom
	.m1-converter {
		display: flex;
		align-items: center;
		.m1-converter-child {
			&--unite {
				font-size: 12px;
				background-color: #e0d3b3;
				padding: 4px 8px;
				border-radius: 25px;
				margin-right: 4px;
				margin-left: 4px;
			}
		}
	}
	.m1-qty-by-qt-wrapper {
		display: flex;
		align-items: center;
		input[type="number"] {
			min-width: 60px;
			appearance: none;
			background-color: white !important;
			margin-left: 8px;
			margin-right: 8px;
		}
		button {
			background-color: #000;
			color: white;
			padding: 0 0.4rem;
			height: auto;
			border: none;
			appearance: none;
		}
	}
	.product-add-to-cart {
		.product-quantity .qty {
			width: 100px;
		}
	}

	.kl-product-single--item {
		position: relative;
		border: 1px solid #eee;

		.kl-tag.kl-zoom.show-productmodal {
			top: 0;
			left: 0;
		}
	}
}

#m1-product-description {
	text-align: center;
}

#m1-product-buy-with {
	background-color: $beige;
	padding-top: 4rem;
	padding-bottom: 4rem;
	margin-top: 4rem;

	.wrapper {
		padding: 3rem;
		background-color: white;

		div#idxrrelatedproducts-ficha-producto {
			padding: 0px;

			@media screen and (max-width: 767px) {
				display: block;

				> div {
					width: 100% !important;
					max-width: 100% !important;
				}
			}

			.separador {
				@media screen and (max-width: 767px) {
					height: 50px !important;
				}

				&::after {
					background: url("../img/icons/more-btn.svg") no-repeat 50% 50% / contain;
				}
			}

			.nombre-producto {
				text-transform: uppercase;
				color: $primary-dark;
				font-weight: 500;
				margin-bottom: 1rem;
				font-family: "DM sans";
				font-size: 16px;
				line-height: 1.2;
				height: auto;

				a,
				span {
					display: block;
					font-size: 16px;
					line-height: 1.2;
				}
			}

			.producto-item .precio-producto {
				height: auto;
				text-align: left;

				> span {
					font-weight: 500;
					color: $primary-dark;
				}
			}

			.selector-compra {
				margin-top: 0;
			}

			.toggle {
				position: relative;
				width: 64px !important;
				height: 34px !important;
				min-width: 64px !important;
				min-height: 34px !important;
				border: 1px solid $primary-dark;
				border-radius: 58px;
				display: block;
				transition: background-color 300ms ease;
				margin-top: 1rem;
				background-color: $primary-dark;
				opacity: 1 !important;

				&.off {
					background-color: white;

					.toggle-group {
						.toggle-handle.btn.btn-default {
							background-color: $primary-dark;
							position: absolute;
							transform: translateX(0px);
						}
					}
				}
			}

			.toggle-group {
				left: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;

				label {
					display: none;
				}

				.toggle-handle.btn.btn-default {
					display: block;
					position: absolute;
					left: 2px;
					top: 2px;
					width: 28px;
					height: 28px;
					background-color: #fff;
					border-radius: 58px;
					transform: translateX(30px);
					transition: transform 300ms ease;
					border: none;
				}
			}
		}

		div#idxrrelatedproducts-add-to-cart {
			padding: 0;

			.precio-final {
				border: 2px solid $primary-dark;
				color: $primary-dark;
			}

			a,
			button,
			a.anadir-al-carrito {
				background-color: $primary-dark;
			}
		}

		.col-product {
			.thumb {
				height: 140px;
				margin-bottom: 1rem;

				img {
					background-color: #eee;
					width: auto;
					height: 100%;
				}
			}

			.content {
				.title-product {
					text-transform: uppercase;
					color: $primary-dark;
					font-weight: 500;
					margin-bottom: 1rem;
				}

				.price {
					display: flex;
					align-items: center;

					.price {
						font-weight: 500;
						color: $primary-dark;
						margin-right: 16px;

						> span {
							font-size: 12px;
							font-weight: 400;
						}
					}

					.old_price {
						font-size: 12px;
					}
				}

				.switch > label {
					display: block;

					> .fake-box {
						position: relative;
						width: 64px;
						height: 34px;
						border: 1px solid $primary-dark;
						border-radius: 58px;
						display: block;
						transition: background-color 300ms ease;
						margin-top: 1rem;

						span.rond {
							display: block;
							position: absolute;
							left: 2px;
							top: 2px;
							width: 28px;
							height: 28px;
							background-color: $primary-dark;
							border-radius: 58px;
							transform: translateX(0px);
							transition: transform 300ms ease;
						}
					}

					input[type="checkbox"] {
						display: none;

						&:checked {
							~ .fake-box {
								background-color: $primary-dark;

								> span.rond {
									transform: translateX(30px);
									background-color: white;
								}
							}
						}
					}
				}

				.switch {
					input[type="checkbox"]:checked {
						~ label {
							background-color: $primary-dark;
						}

						~ label > span.rond {
							transform: translateX(30px);
						}
					}
				}
			}
		}

		.col-separator {
			display: flex;
			align-items: center;
			justify-content: center;

			> i {
				width: 30px;
				height: 30px;
				background: url("../img/icons/more-btn.svg") no-repeat 50% 50% / contain;
				display: block;
			}
		}
	}
}

#m1-product-options,
#m1-prodquestions,
#m1-product-buy-with {
	.title {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 1rem;
		color: $primary;
		text-align: center;
		line-height: 1.1;

		@media screen and (min-width: 767px) {
			display: inline-flex;
			align-items: center;
		}

		&::before {
			content: "";
			height: 50px;
			width: 50px;
			display: block;
			margin: 0 auto;
			margin-bottom: 1rem;
			background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;

			@media screen and (min-width: 767px) {
				display: inline-block;
				margin-right: 16px;
				margin-bottom: 0;
			}
		}
	}
}

#m1-product-options {
	padding-bottom: 4rem;

	.m1homemadevariations {
		overflow-x: scroll;

		table {
			border-bottom: 2px solid $primary-light;

			thead {
				background-color: $primary;
				color: white;
				max-width: 140px;

				td {
					padding: 1rem 1.25rem;
					font-size: 16px;
					text-align: center;
				}
			}

			tbody {
				font-size: 14px;

				tr:nth-child(odd) {
					background-color: $beige;
				}

				tr:nth-child(even) {
					background-color: rgba(#e0d3b3, 0.35);
				}

				td {
					text-align: center;
					padding: 1rem 1.4rem;
				}

				td.reference {
					max-width: 180px;
					font-size: 13px;
					vertical-align: middle;
					word-wrap: break-word;
				}

				td.ep {
					min-width: 150px;
				}

				td.prix {
					min-width: 280px;

					.prix-normal {
						display: block;
						font-size: 12px;
						text-decoration: line-through;
						color: $primary-light;
					}

					.prix-spe {
						font-weight: 500;
					}
				}
				td.commander {
					vertical-align: middle;
				}

				td.commander > form > .product-quantity > div {
					display: flex;
					align-items: center;
					min-width: 500px;

					.liaison {
						background-color: rgba(#e0d3b3, 0.45);
						padding: 0.3rem 0.625rem;
						border-radius: 35px;
						margin: 0 0.7rem;
						display: inline-block;
						font-size: 14px;
						color: $primary-light;
					}

					label {
						margin-bottom: 0;
					}

					.label {
						font-weight: 500;
						padding-right: 8px;
					}

					input[type="number"] {
						width: 110px;
						border-radius: 0 !important;
						border: 2px solid #eee;
						padding: 0.3rem;
						color: $primary-dark;
					}

					.decli-qty {
						label {
							display: flex;
							align-items: center;
						}

						.wrap-input-qty {
							position: relative;

							input[type="text"] {
								padding: 0.625rem 0rem 0.625rem 0.5rem;
								//width: 80px;
								width: 55px;
								border: 2px solid rgba(#1c2827, 0.15);
								border-right: 0;
								text-align: left;
								font-size: 13px;
								background-color: transparent;
								margin-right: 30px;
							}

							button {
								position: absolute;
								height: 50%;
								display: flex;
								align-items: center;
								justify-content: center;

								i {
									font-size: 16px;
								}

								&.moins {
									bottom: 0;
									right: 0;
									border: 2px solid rgba(#1c2827, 0.15);
									border-left: 0;
									border-top: 0;
									background-color: transparent;
								}

								&.plus {
									top: 0;
									right: 0;
									border: 2px solid rgba(#1c2827, 0.15);
									border-left: 0;
									border-bottom: 0;
									background-color: transparent;

									i {
										transform: rotate(-180deg);
									}
								}
							}
						}
					}

					.decli-option {
						position: relative;

						> label {
							display: flex;
							align-items: center;
						}

						input[type="text"] {
							padding: 0.625rem 0rem 0.625rem 1rem;
							//width: 80px;
							width: 70px;
							border: 2px solid rgba(#1c2827, 0.15);
							border-right: 0;
							text-align: right;
							font-size: 13px;
							background-color: transparent;
						}

						.unite {
							padding: 0.625rem 1rem 0.625rem 0.3rem;
							//background-color: white;
							border: 2px solid rgba(#1c2827, 0.15);
							border-left: 0;
							font-size: 13px;
						}
					}

					button.decli-add-to-cart {
						width: 40px;
						height: 40px;
						display: inline-block;
						border: 0px;
						background: $primary url("../img/icon-add-to-cart-mini.svg") no-repeat 50% 50% / 15px;
						margin-left: 16px;
					}
				}
			}
		}
	}
}

// fiche tchnique

.kl-detail-product--list .kl-detail-product--item {
	background-color: $beige;
}

.kl-aff-list:after {
	display: none;
}

.m1-similar-product {
	padding-top: 3rem;
	padding-bottom: 3rem;

	.js-product-miniature {
		.kl-img-xts {
			margin-bottom: 2rem;
		}

		.cdpcp-compare-btn--add {
			display: none;
		}

		.kl-title-aff {
			span,
			p {
				font-style: normal;
			}

			p[itemprop="name"] {
				font-weight: 500;
			}
		}
	}
}

#lgcomment {
	.productComment {
		.nickname {
			display: block;
		}

		.content-block {
			border-left: none;
			padding-left: 0;
			padding-right: 0;
		}

		.answer {
			background-color: $beige;
			padding: 1rem;
			color: $primary-light;

			p:only-child,
			p:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#m1-prodquestions {
	background-color: $beige;
	padding-top: 4rem;
	padding-bottom: 4rem;

	.container {
		background-color: white;
		padding: 2rem;
	}

	.m1-question {
		.m1-box-question {
			.m1-label-response {
				margin-bottom: 1rem;
			}
		}

		.m1-box-response {
			padding: 1rem;
			background-color: $beige;
		}
	}

	#m1-questions-pagination {
		margin-top: 2rem;

		.pages {
			span {
				color: $primary-dark !important;
			}

			span.nums b {
				background-color: $primary-dark;
				padding: 0.3rem 1rem;
			}
		}
	}

	#question-form {
		#add-question-form {
			.btn-primary-prodquestions,
			.btn-success-custom,
			.btn-success-custom:hover,
			.btn-success-custom:focus {
				background-color: $primary-dark !important;
				padding: 0.655rem 2rem;
			}

			.title-rev {
				color: $primary-dark;
			}
		}
	}

	#button-bottom-add-question {
		background-color: $primary-dark !important;
		padding: 0.655rem 2rem;
	}
}

//share RS
.share-list {
	li {
		display: inline-block;

		a {
			display: block;
			background-color: $primary-dark;
			transition: background-color 300ms ease;
			i {
				width: 40px;
				height: 40px;
				display: block;
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}

			&:hover {
				background-color: $bleu;
			}
		}

		i.icon-facebook {
			background-image: url("../img/icons/facebook.svg");
			background-size: 10px;
		}

		i.icon-mail {
			background-image: url("../img/icons/email.svg");
			background-size: 18px;
		}

		i.icon-link {
			background-image: url("../img/icons/link.svg");
			background-size: 20px;
		}
	}
}

/* avis client */
.grade-stars {
	.star-on {
		background: url("../img/etoile-avis.svg") no-repeat 50% 50% / contain !important;
	}
}

// question des internautes
#body-add-prodquestions-form label,
#body-add-prodquestions-form .label-span {
	margin-top: 0;
}

#m1homemadevariations_side {
	margin-top: 2rem;
}

.ul-wrapper-relative {
	position: relative;
	height: $select-height;
}

.m1-select-options-title {
	font-weight: 500;
	margin-bottom: 0.5rem;
	color: $primary-dark;
}

#extra-0 {
	margin-bottom: 4rem;
}

.kl-bg-grey {
	background-color: $beige;
}

.kl-dj-list.row > .col-12 > .row {
	@media screen and (max-width: 767px) {
		display: block !important;
	}

	> .col-md-10.d-flex {
		@media screen and (max-width: 767px) {
			display: block !important;
			padding: 1rem;

			p {
				text-overflow: clip;
				display: inline-block;
				word-break: break-all;
				hyphens: manual;
				font-size: 14px;

				span {
					display: block;
					text-overflow: ellipsis;
					word-break: break-all;
					hyphens: manual;
				}
			}
		}
	}
}
