.modal-header {
	background-color: $primary-dark;
	text-align: center;
	justify-content: center;
	#myModalLabel {
		color: white;
	}
	.close {
		text-shadow: none;
		position: absolute;
		top: 8px;
		right: 8px;
	}
	.modal-title {
		font-weight: 500;
		color: white;
		font-size: 24px;
	}
}

.modal-header .close span {
	color: white;
}

.cdpcp-compare-modal__dialog.modal-dialog {
	.cdpcp-table__delete.btn.btn-tertiary {
		background-color: red;
	}
	.modal-header {
		flex-direction: column;
		.modal-title {
			display: block;
		}
		a {
			color: white;
			display: block;
			&:hover {
				color: #e0d3b3;
			}
		}
	}
}

.cdpcp-table__cell-inner.cdpcp-table__product-inner {
	.cdpcp-table__product-link {
		.cdpcp-table__name {
			font-size: 20px;
			font-weight: 500;
            color: $primary-dark;
		}
	}
}

.cdpcp-table__quickview {
    background-color: $bleu;
    letter-spacing: 0;
    font-weight: 400;
    text-decoration: none !important;
    &:hover {
    }
}

.cdpcp-table__desc {
    font-size: 14px;
    font-weight: 400;
    color: $primary-light;
}

#blockcart-modal .modal-dialog {
	@media screen and (min-width: 767px) {
		margin-top: 12rem !important;  
	}
}