button.slick-prev.slick-arrow,
button.slick-next.slick-arrow {
	background-size: 15px !important;
	background-image: url("../img/icons/arrow-right.svg");
	transition: background-color 300ms ease;
	&:hover {
		background-color: #e0d3b3 !important;
		background-image: url("../img/icons/arrow-right.svg") !important;
	}
	> svg,
	&::before {
		display: none;
	}
}
.slick-prev {
	transform: rotate(-180deg);
}
