body#checkout section.checkout-step .custom-radio input[type="radio"] {
	height: 0.8rem !important;
	width: 1rem !important;
}

#checkout-personal-information-step {
}

#checkout {
	.custom-checkbox {
		font-size: 14px;
	}
}
.custom-checkbox input[name="psgdpr"],
.custom-checkbox input[name="newsletter"],
.custom-checkbox input[name="optin"] {
	min-width: 1rem;
	min-height: 1rem;
	margin-right: 0.5rem;
}

body#checkout section.checkout-step .forgot-password {
	margin-left: 0;
	@media screen and (min-width: 768px) {
		margin-left: 14.38rem;
	}
}


body#checkout section.checkout-step .payment-options .custom-radio {
    margin-right: .25rem;
}

body#checkout section.checkout-step .payment-options .payment-option {
    margin-bottom: 0.5rem;
    display: flex;
}

section.checkout-step #conditions-to-approve input[name="conditions_to_approve[terms-and-conditions]"] {
    width: 1rem;
    height: 1rem;
}