/***
*
* Breadcrumbs
*
**************/
.kl-hero-listing.kl-first-block {
	background: $bleu;
	.kl-breadcrumb {

		background: transparent;
		ul > li > {
			a,
			span {
				font-style: normal;
				line-height: 1;
			}
		}
	}
}

/***
*
* Hero
*
**************/
.kl-category-section {
	padding-top: 2rem;
	padding-bottom: 4rem;
	background: $bleu;
	box-shadow: inset 0 -1px 0 0 #e6e6e6;
	.rounded-img {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		overflow: hidden;
		img {
			opacity: 1;
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}
.kl-category-section .kl-intro {
	@media screen and(min-width: 1023px) {
		text-align: left;
	}
}
.kl-category-section .kl-intro h1 {
	font-size: 20px;
	text-align: center;
	margin-bottom: 2rem;
	@media screen and(min-width: 1023px) {
		text-align: left;
		font-size: 32px;
		line-height: 1.1;
		font-weight: 700;
	}
	&::before {
		content: "";
		width: 40px;
		height: 40px;
		background: url("../img/puce-titre-blanc.svg") no-repeat 50% 50% / contain;
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0.7rem;
    vertical-align: middle;
    margin-right: 16px;
	}
}
#category-description {
	height: auto !important;
	margin-bottom: 0;
	overflow: hidden;
	text-align: left;
	//opacity: 0;
	transition: opacity 300ms ease;
	color: white;
	.readmore-dots {
		display: inline;
	}
	.desc {
		overflow: hidden;
	}

	.show-more, .show-less {
		background-color: white;
		color: $primary;
		padding: 0.5rem 1rem;
		margin-top: 2rem;
		border: 1px solid white;
		cursor: pointer;
		&:hover {
			color: white;
			background-color: transparent;
		}
	}
}

// subcategories list
.title-subcategories {
	font-size: 24px !important;
	text-align: center;
	font-weight: 500 !important;
	color: $primary-dark;
	&::before {
		content: "";
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 50px;
		height: 50px;
		background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;
		margin-bottom: 1rem;
	}

	@media screen and (min-width: 768px) {
		position: relative;
		padding-left: 70px;
		display: table;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
		&::before {
			position: absolute;
			top: 50%;
			left: 0;
			width: 50px;
			height: 50px;
			transform: translateY(-50%);
		}
	}
}

.kl-subtitle {
	max-width: 710px;
	margin-left: auto;
	margin-right: auto;
}

.kl-sub-categories-list {
	@media screen and (min-width: 768px) {
		display: flex;
	}
	> div {
		border: none;
		padding: 1rem 1rem;

		height: auto;
	}
	> div > a {
		border: 2px solid #eee;
		display: block;
		height: 100%;
		text-align: center;
		padding: 1rem;

		.kl-img {
			height: 100px;
		}
		h3 {
			display: inline-block;
			word-wrap: break-word;
			font-size: 16px;
			font-weight: normal;
		}
	}
}

/***
*
* Left column
*
**************/
#left-column {
  @media screen and (min-width: 1023px) {
    padding-right: 32px;
  }
}
//categories
#left-column .block-categories {
  margin-bottom: 2rem;
	> ul.category-top-menu > li:first-child {
		font-weight: 700;
		font-size: 16px;
	}
	> ul.category-top-menu > li:not(:first-child) {
		> ul.category-sub-menu {
			> li[data-depth="0"] {
				border-bottom: 1px solid #eee;
				> a {
					font-size: 14px;
					font-weight: 500;
					margin: 0;
					padding: 0.5rem 0;
				}
				.collapse-icons {
					top: 0.8rem;
				}
				ul.category-sub-menu {
					> li[data-depth="1"] {
						a.category-sub-link {
							font-size: 14px;
							font-weight: 400;
							padding-left: 0;
              display: inline-block;
						}
            .arrows {
              vertical-align: middle;
              display: inline-block;
            }
						.category-sub-menu {
							> li[data-depth="2"] {
								font-size: 14px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}


.kl-category-section .kl-intro .kl-see-more {
	font-size: 16px;
	font-weight: 400 !important;
	letter-spacing: 0px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	@media screen and(min-width: 1023px) {
		text-align: left;
		display: flex;
	}
}

.block-categories {
	a {
		margin-bottom: 0;
	}
	.category-sub-menu {
		.material-icons.add,
		.material-icons.remove,
		.material-icons.arrow-right,
		.material-icons.arrow-down {
			font-size: 22px;
		}

	}
	.arrows {
		.material-icons.arrow-down {
			display: none;
		}
		.material-icons.arrow-right {
			display: inline-block;
		}
	}
	.arrows.collapsed {
		.material-icons.arrow-right {
			display: none;
		}
		.material-icons.arrow-down {
			display: inline-block;
		}
	}
}

.kl-title-sidebar,
.kl-title-filter {
	letter-spacing: 0;
	color: $primary-dark;
	padding-bottom: 0;
}

.faceted-mobiles {
	margin-bottom: 3rem;
    @media screen and(min-width: 1024px) {
        display: none;
    }
	.kl-acc-item {
		margin-bottom: 0;
	}
	.kl-acc-item .kl-acc {
		padding: 12px 16px;
		border-radius: 0;
		background-color: $beige;
		border: 0px;
	}
	.PM_ASCriterionsGroup,
	.kl-bx-filter--item {
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}
}

#search_filters_wrapper {
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		text-align: center;
	}
}

#seo-description {
	h3 {
		font-size: 22px;
		font-weight: 500;
		color: $primary;
	}
	p {
		color: $primary-light;
	}
}
