body {
	font-family: "DM sans" !important;
	color: $primary-light;
	.container {
		@media screen and (min-width: 1400px) {
			max-width: 1200px;
		}
	}
}

.m1-read-more {
	&-content {
		overflow: hidden;
		transition: height 300ms ease-out;
		> * {
			line-height: 23px !important;
			color: $primary-light !important;

			@media screen and (min-width: 768px) {
				line-height: 26px !important;
			}
		}

		&.closed {
			~ .m1-read-more-btn {
				//background-color: red;
				.m1-read-more-btn--arrows {
					.material-icons:first-child {
						display: inline;
					}
					.material-icons:last-child {
						display: none;
					}
				}
			}
		}

		@media screen and (max-width: 500px) {
			text-align: center;
		}
	}

	&-btn {
		display: flex;
		align-items: center;
		color: $primary;
		margin-top: 1rem;
		margin-bottom: 1rem;
		> span {
			display: inline-block;
			margin-left: 3px;
		}
		&--arrows {
			.material-icons:first-child {
				display: none;
			}
			.material-icons:last-child {
				display: inline;
			}
		}

		@media screen and (max-width: 500px) {
			margin-left: auto;
			margin-right: auto;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 1199px) {
	.kl-content-box {
		//padding: 50px 0 !important;
		font-size: 15px;
	}
}


.material-icons {
	font-size: inherit;
}