// page type
#cms.cms-id-6 {
	color: $primary-light;
	h1,
	.subtitle {
		color: $primary-dark;
	}
	.subtitle {
		margin-bottom: 1rem;
		font-weight: 500;
		font-size: 22px;
	}
	h1 {
		font-size: 32px;
		text-align: center;
		font-weight: 500 !important;
		&.text-left {
			@media screen and (max-width: 400px) {
				text-align: center !important;
			}
		}
		&::before {
			content: "";
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 50px;
			height: 50px;
			background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;
			margin-bottom: 1rem;
		}

		@media screen and (min-width: 768px) {
			position: relative;
			padding-left: 70px;
			display: table;
			margin-left: 0;
			margin-right: auto;
			vertical-align: middle;
			padding-top: 1rem;
			padding-bottom: 1rem;
			margin-bottom: 2rem;
			&::before {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: 60px;
				height: 60px;
			}
		}
	}
	.full-width-bg-beige {
		background-color: $beige;
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		padding-top: 4rem;
		padding-bottom: 4rem;
		margin-top: 4rem;
	}

	.full-width-ecolo {
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		margin-bottom: 2rem;
		background: url("../img/visuel-feuillage.jpg") no-repeat 50% 50% / cover;
		.row {
			align-items: center;
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
		.col-md-6 {
			color: white;
		}
		.col-md-6:first-child {
			text-align: right;
			font-size: 35px;
			font-weight: 500;
			display: inline-block;
		}
	}
}
