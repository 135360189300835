#order-confirmation {
	#content-hook_order_confirmation {
		padding-top: 0;
		.h1.card-title {
			font-size: 24px;
			font-weight: 500;
			color: $primary;
			@media screen and (min-width: 768px) {
				font-size: 26px;
				i {
					font-size: 30px;
					margin-right: 8px;
				}
			}
		}
	}

	#content {
		.card-title {
			font-size: 20px;
			font-weight: 500;
			color: $primary;
			@media screen and (min-width: 768px) {
				font-size: 24px;
			}
		}

		#order-items {
			> .row {
				background-color: $primary;
			}
			.card-title {
				font-size: 18px;
				color: white;
				margin: 1rem 0;
			}
		}

		.order-confirmation-table {
			.order-line:not(:last-child) {
				border-bottom: 1px solid #eee;
			}
		}

		#order-details {
			padding: 2rem;
			background-color: $beige;
			ul {
				margin-bottom: 0;
			}
		}
	}
}
