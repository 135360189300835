@media screen and (min-width: 1200px) {
	.wsmenu {
		position: unset;
	}
	.wsmenu > .wsmenu-list > li > .wsmegamenu {
		top: auto;
		background-color: #fbf9f4;
		position: absolute;
		z-index: 1;
		border-bottom: 2px solid #1c2827;
		.wstheading {
			font-weight: 500;
			font-size: 14px;
		}
	}
	.wsmenu > .wsmenu-list {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		> li > a {
			font-size: 14px;
			font-weight: 500;
			> span {
				display: inline-block;
			}
			.arrow-down {
				display: inline-block;
				vertical-align: middle;
				width: 8px;
				height: 8px;
				margin-left: 8px;
				background-color: red;
				background: url('../img/dropdown.svg') no-repeat center / contain;
			}
		}
		@media screen and (max-width: 1198px) {
			> li > a.has-children {
				i {
					display: none;
				}
			}
		}
		@media screen and (min-width: 1199px) {
			> li {
				height: 100%;
				display: block;
				margin-left: 12px;
				margin-right: 12px;
				> a.has-children {
					display: inline-flex;
					color: $primary-dark;
					span {
						display: inline-block;
					}
					i {
						display: inline-block;
						font-size: 16px;
						transform: rotate(90deg);
					}
				}
			}

			a {
				text-transform: lowercase;
				display: block;
				line-height: 1;
				transition: box-shadow 300ms ease;
				color: $primary-dark;
				&::first-letter {
					text-transform: uppercase;
				}
			}
			> li > .navtext {
				padding: 2.325rem 0px !important;
				> span {
					line-height: 1.1;
					color: $primary-dark;
					&::first-letter {
						text-transform: uppercase;
					}
				}
			}
			> li:hover > a {
				box-shadow: 0px -6px 0px -1px inset #1c2827;
			}
			> li > ul.sub-menu {
				transform: translateY(30px);
				top: auto;
				bottom: auto;
				//opacity: 1;
				//visibility: visible;
				> li > a {
					display: block;
					padding: 0.625rem 1rem;
				}
			}
			> li:hover > ul.sub-menu {
				transform: translateY(0);
			}
		}
	}
}


.modal-open {
	.navbar.navbar-expand-lg {
		z-index: 999999 !important;
	}
}

.cart-search i {
    color: #fff !important;
    font-size: 21px;
}