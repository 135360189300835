.js-product-miniature {
    display: flex;
    flex-direction: column;
    .kl-img-xts {
        border: 1px solid #eee;
        margin-bottom: 1rem;
    }
	.kl-title-aff {
		background-color: white;
        a:first-child {
            font-weight: 500;
        }
		span {
			font-style: normal;
			font-size: 14px;
			margin-top: 1rem !important;
			display: inline-block;
			margin-bottom: 1rem !important;
		}
	}
	.kl-img-xts {
		height: 265px;
	}
	.prdt_content {
		padding-bottom: 2rem;
        flex-grow: 1;
	}
    .kl-row-listing .prdt_content .kl-title-aff ul {
        padding-top: 0 !important;
        margin-top: auto;
    }
}
.item .kl-img-xts img,
.kl-product--item-img img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
	object-position: top 50% right 50%;
	opacity: 1;
}

.item.kl-xts-item:hover > div {
	outline: none;
}
.cdpcp-compare-btn {
	background-color: white;
	color: $primary-dark;
	border: 1px solid #eee;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
	i {
		display: inline-block;
		width: 16px;
		height: 16px;
		position: relative;
		margin-right: 16px;
		&::before,
		&::after {
			content: "";
			background-color: $primary-light;
			position: absolute;
			height: 90%;
			width: 3px;
			left: calc(50% - 0.5px);
            border-radius: 80px;
		}
		&::after {
			transform: rotate(90deg);
		}
	}
	&.cdpcp-compare-btn--remove {
        background-color: $primary;
        border-color: $primary;
        color: white;
		i {
            &::before, &::after {
                background-color: #fff;
            }
            &::after {
                transform: rotate(45deg);
            }
            &::before {
                transform: rotate(-45deg);
            }
		}
	}
}
