.btn-o.hvr-sweep-to-right, .hvr-sweep-to-right {
	background-color: $primary;
	color: white !important;
	padding: 0.625rem 2rem;
    z-index: 1;
	&::before {
		background-color: $bleu !important;
	}

    &:hover { 
        text-decoration: none !important;
    }
}

.kl-btn-compte.hvr-shutter-out-horizontal {
    text-decoration: none !important;
}
.btn-o.hvr-sweep-to-right, .hvr-sweep-to-right {
    background-color: $primary;
}