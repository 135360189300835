// topbar
.kl-head-top {
	> .kl-topbar {
		background-color: $primary;
		padding: 0.325rem 0;
		height: auto;
		ul {
			color: white;
			a {
				color: white;
				text-decoration: underline;
			}
		}

		.kl-btn-compte {
			background-color: #fff;
			color: $primary-dark !important;
			padding: 0.5rem 2rem;
			&::before {
				background-color: $primary !important;
			}
			&:hover {
				color: white !important;
			}
		}
	}
}
.kl-contact-top {
	background: url("../img/icon-fast-delivery.svg") no-repeat left 0 top 50% / 24px;
	padding-left: 34px;
	font-weight: 700;
	a {
		font-weight: 400;
	}
}

.kl-tel-top-header {
	color: white;
	font-size: 14px;
	text-decoration: none;
	display: flex;
	align-items: center;
}

.kl-tel-top-header > a {
	margin-left: 16px;
	background: url("../img/icon-tel.svg") no-repeat left center / 20px;
	padding-left: 30px;
}

.kl-tel-top-header .mail {
	width: 251px;
	height: 14px;
	background: url("../img/image-mail.png") no-repeat 50% 50% / contain;
	margin-left: 16px;
	display: inline-block;
}

.navbar.navbar-expand-lg {
	box-shadow: 0 4px 8px #00000030;
	position: relative;
	z-index: 999;
	@media screen and (max-width: 1100px) {
		height: 86px;
	}
	> .container {
		> .row {
			width: 100%;
			.navbar-brand {
				margin-right: 32px;
				max-width: 130px;
				@media screen and (min-width: 1000px) {
					padding-top: 0;
					padding-bottom: 0;
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}
.navbar-expand-lg.navbar-light.kl-nav-menu {
	padding: 0;
}
.wsmobileheader {
	padding-bottom: 1rem;
	padding-top: 1rem;
	height: 86px;
}
.cart-search {
	margin-left: auto;
}
.headerfull {
	flex: 1;
}

.kl-header-product {
	margin-top: 1rem;
	padding-top: 0;
}

// headeer icons
#actionShowSearchBar,
.blockcart > .kl-link-choice {
	background-color: rgba(#d9dde8, 50%);
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 300ms ease;
	&:hover {
		background-color: #fff;
	}
}
.blockcart > .kl-link-choice {
	> span {
		position: absolute;
		font-size: 12px;
		color: white;
		background-color: red;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		right: 0;
		top: 0;
	}
}

.m1_icon-search,
.m1_icon-cart {
	width: 20px;
	height: 20px;
	display: block;
	background-size: contain;
	background-repeat: no-repeat;
}
.m1_icon-search {
	background-image: url("../img/icon-search.svg");
}
.m1_icon-cart {
	background-image: url("../img/icon-cart.svg");
	width: 23px;
	height: 23px;
}

// blockcart
.blockcart {
	.kl-choice {
		> span {
			background-color: red !important;
		}
	}
}

.wsmobileheader .wssearchform form:before {
	display: none;
}

.wsmobileheader .wssearchform input {
	text-indent: 10px;
}

#searchModal {
	z-index: 999999 !important;
}
