

#index {
	
	#carouselExampleIndicators {
		@media screen and (max-width: 767px) {
			min-height: 500px;
		}

		.carousel-control-prev,
		.carousel-control-next {
			width: 60px !important;
		}
		.carousel-item {
			//position: relative;
			align-items: flex-end;
			@media screen and (max-width: 767px) {
				min-height: 500px;
			}
			.img-fluid {
				max-height: 400px;
				@media screen and (min-width: 1200px) {
					width: 55%;
					height: auto;
					max-height: 100%;
				}
			}

			.slide-bleu.m1-slider-left-caption {
				background: $bleu;
				@media screen and (min-width: 768px) {
					background: $bleu url("../img/bg-home-slider.png") no-repeat -90px bottom / 420px;
					&::before {
						background-color: $bleu;
					}
				}
			}
			.slide-noir.m1-slider-left-caption {
				background: $primary;
				@media screen and (min-width: 768px) {
					background: $primary url("../img/bg-home-slider.png") no-repeat -90px bottom / 420px;
					&::before {
						background-color: $primary;
					}
				}
			}
			.m1-slider-left-caption {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				display: flex;
				text-align: center;
				color: white;
				padding-top: 3rem;
				padding-bottom: 3rem;
				padding-right: 3rem;
				padding-left: 3rem;
				width: 100%;

				@media screen and (min-width: 768px) {
					width: 50%;
					align-items: flex-start;
					text-align: left;
					&::before {
						content: "";
						position: absolute;
						right: 0;
						top: 0;
						width: 100px;
						background-color: $bleu;
						height: 100%;
						transform: translateX(50%) skewX(-6deg);
					}
					&::after {
						content: "";
						width: 108px;
						height: 75px;
						position: absolute;
						right: -80px;
						bottom: 80px;
						background: url("../img/feuille.png") no-repeat 50% 50% / contain;
					}
				}
				@media screen and (min-width: 1200px) {
					padding-left: 12rem;
					width: 45%;
				}
				.list-badge {
					list-style-type: none;
					margin-bottom: 2rem;
					li {
						display: inline-block;
						font-weight: 700;
						padding: 0.5rem 1rem;
						color: white;
						&.badge-plein {
							background-color: white;
							color: $primary;
							text-transform: uppercase;
						}
					}
				}
				.titre-slide {
					font-size: 33px;
					font-weight: 700;
					line-height: 1.1;
					margin-bottom: 2rem;
				}
				.desc-slide {
					margin-bottom: 2rem;
				}
				.bouton-slide,
				a {
					background-color: white;
					border-radius: 50px;
					padding: 0.625rem 1.3rem 0.625rem 2.1rem;
					display: inline-block;
					transition: background-color 300ms ease;
					> span {
						transform: translateX(0);
						transition: transform 300ms ease, background-color 300ms ease;
					}
					&:hover {
						text-decoration: none;
						background-color: #e0d3b3;
						> span {
							transform: translateX(5px);
						}
					}
				}
			}
		}
	}

	h2, .m1-h2 {
		font-size: 24px;
		text-align: center;
		font-weight: 500 !important;
		color: $primary-dark;
		line-height: 1.3;
		margin-bottom: 2rem;
		&.text-left {
			@media screen and (max-width: 400px) {
				text-align: center !important;
			}
		}

		&::before {
			content: "";
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 50px;
			height: 50px;
			background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;
			margin-bottom: 1rem;
			@media screen and(min-width: 768px) {
				display: inline-block;
				margin-bottom: 0;
				vertical-align: middle;
				margin-right: 16px;
			}
		}

	}

	.kl-bx-content {
		p {
			max-width: 600px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.kl-slick-product.ps_featuredproducts {
		.kl-aff-list::after {
			display: none;
		}

		.slick-slide {
			.cdpcp-compare-btn {
				display: none;
			}
			.kl-img-xts {
				border: 1px solid #eee;
				min-height: 262px;
				.kl-see {
					left: 0;
				}
			}
			.kl-title-aff {
				background-color: transparent;
				span[itemprop="description"] {
					font-style: normal;
					font-size: 14px;
					color: $primary;
				}
			}
		}
	}

	// large sélection de produit
	.kl-block-offer.seopresta_bannerhome {
		background-color: #fff;
		.kl-bx-content {
			margin-top: 0;
		}
		.kl-img-offer {
			.col-md-4 {
				> .d-flex {
					padding: 1.5rem 1rem;
					color: white;
					background-color: $primary;
					align-items: center !important;
					h3 {
						color: white;
						font-size: 14px;
						font-style: normal;
						font-weight: 500 !important;
						margin-bottom: 0 !important;
						@media screen and(min-width: 1024px) {
							font-size: 18px;
						}
					}
					i {
						color: white !important;
					}
				}
			}
		}
	}
}

.ps_featuredproducts {
	.slick-dotted.slick-slider {
		padding-bottom: 100px !important;
	}
}
.js-product-miniature .kl-title-aff > a,
.kl-title-aff .h3,
.kl-title-aff h3 {
	letter-spacing: 0 !important;
	text-decoration: none !important;
}

.js-product-miniature a:hover {
	text-decoration: none;
}
.slick-dots {
	li {
		width: 10px;
		button {
			background-color: #d9dde8;
			border-radius: 50%;
			width: 10px;
			height: 10px;
		}

		&.slick-active,
		&:hover {
			button {
				background-color: $primary;
			}
		}
		button::before {
			display: none;
		}
	}
}

.kl-block-mode.kl-content-box.seoprestahometext {
	background-color: rgba(#e0d3b3, 15%);
	@media screen and (min-width: 900px) {
		padding: 6rem 0 !important;
	}
	.container > .row {
		align-items: center;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	#homedescription {
		p {
			font-weight: 400 !important;
			font-size: 15px !important;
		}
	} 
}
// marques
.kl-block-actuality.seopresta_manufacturerslider.kl-bx-block {
	margin-bottom: 0;
	margin-top: 0;
	> .container > .row > .kl-bx-content {
		margin-bottom: 0;
	}
	.slick-slide {
		display: flex;
		align-items: center;
		img {
			filter: grayscale(100%);
			transition: filter 300ms ease;
		}

		&:hover {
			img {
				filter:grayscale(0%);
			}
		}
	}
	.slick-prev,
	.slick-next {
		background-size: 15px;
		background-image: url("../img/icons/arrow-right.svg");
		&:hover {
			background-color: #e0d3b3 !important;
		}
		> svg,
		&::before {
			display: none;
		}
	}
	.slick-prev {
		transform: rotate(-180deg);
	}
	@media screen and (min-width: 900px) {
		padding-bottom: 4rem;
		padding-top: 4rem;
	}
}

.seoprestahomebanner.kl-block-single.kl-content-box {
	padding: 0 !important;
	overflow: hidden;
	min-height: auto;
	.m1-bg-img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-position: 50% 50%;
			object-fit: cover;
		}
	}
	h2 {
		margin-bottom: 0;
		color: white !important;
	}
	> .container {
		overflow: hidden;
		@media screen and (max-width: 768px) {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
	.col {
		background-color: rgba($primary, 90%);
		padding: 3rem;
		@media screen and (min-width: 768px) {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
		@media screen and (min-width: 900px) {
			padding: 4rem;
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}
	h2 {
		margin-bottom: 1rem;
		@media screen and (min-width: 900px) {
			margin-bottom: 3rem;
		}
		&::before {
			background-image: url("../img/puce-titre-blanc.svg") !important;
		}
	}
	h2,
	p {
		color: white !important;
	}
	p {
		font-size: 16px;
		background-color: transparent;
		line-height: 1.8;
		padding: 0;

		color: white;
		
		&::before,
		&::after {
			display: none;
		}
	}
}

#seoprestahomeblogpost {
	background-color: transparent;
	.slick-dotted.slick-slider {
		margin-bottom: 2rem !important;
	}
	.slick-slide {
		.kl-title-aff {
			background-color: rgba(#d9dde8, 0.2);
			padding: 0.625rem 0.625rem 2rem 0.625rem;
			.kl-date-blog {
				font-style: normal;
				font-size: 14px;
				font-weight: 400;
				color: $primary;
				margin-bottom: 1rem;
			}
			> a > div > span {
				font-size: 14px;
				font-style: normal;
			}
		}
	}
	.slick-dots {
		bottom: 0;
	}
	.kl-more-slide {
		margin-top: 0;
	}
}

.slick-dotted.slick-slider {
	padding-bottom: 50px !important;
}

.kl-box-content {
	h2 {
		font-size: 24px;
	}
	p {
		color: #585b5f !important;
		font-size: 14px !important;
	}
}

h2 {
	font-size: 24px;
}

.kl-block-service {
	> .container > .row:first-child {
		margin-bottom: 2rem;
	}
}
