.kl-hero-listing .kl-breadcrumb ul > li {
  a::after {
    display: none;
  }
	&:not(:last-child)::after {
		content: ">";
		color: white;
    vertical-align: middle;
    font-weight: 200;
    margin-right: 8px;
    margin-left: 8px;
	}
}
