// reinssurance inté
.kl-block-service.seopresta_reinsurance {
}

#footer {
	.kl-block-service.seopresta_reinsurance {
		background-color: $beige;
		padding-top: 4rem;
		padding-bottom: 4rem;
		.kl-bx-content {
			margin-top: 0;
			margin-bottom: 0;
			h2 {
				font-size: 24px;
				text-align: center;
				font-weight: 500 !important;
				color: $primary-dark;
				&.text-left {
					@media screen and (max-width: 400px) {
						text-align: center !important;
					}
				}
				&::before {
					content: "";
					display: block;
					margin-left: auto;
					margin-right: auto;
					width: 50px;
					height: 50px;
					background: url("../img/puce-titre.svg") no-repeat 50% 50% / contain;
					margin-bottom: 1rem;
				}

				@media screen and (min-width: 768px) {
					position: relative;
					padding-left: 70px;
					display: table;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 2rem;
					&::before {
						position: absolute;
						top: 50%;
						left: 0;
						width: 50px;
						height: 50px;
						transform: translateY(-50%);
					}
				}
			}

			p {
				margin: 1rem auto;
				max-width: 800px;
			}
		}

		.col-md-3 {
			.titre {
				font-size: 18px;
				color: $primary-dark;
				margin-bottom: 1rem;
			}
			.desc {
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	.kl-footer {
		padding-bottom: 0;
		> .container > .row {
			@media screen and (min-width: 768px) and (max-width: 900px) {
				.col-md-3 {
					flex: 0 0 50%;
					max-width: 50%;
					br {
						display: none;
					}

					&:nth-child(2) {
						order: 3;
					}
					&:nth-child(4) {
						order: 4;
					}
				}
			}
		}

		p {
			margin-bottom: 1rem;
		}
	}

	.kl-text-foot {
		margin-bottom: 1rem;
	}

	.kl-newsletter {
		text-align: center;
		@media screen and (min-width: 768px) {
			text-align: left;
		}
		.kl-mail-footer ul li:first-child {
			width: 8%;
		}
	}
	.kl-mail-footer {
		margin-top: 0;
	}
	.kl-mail-footer > p:not(.kl-text-foot) {
		text-align: center;
		font-size: 13px;
		@media screen and (min-width: 768px) {
			text-align: left;
		}
	}
}

.kl-footer-seo {
	background-color: #000 !important;
	padding-top: 1rem;
	padding-bottom: 1rem;
	> .container > .row {
		flex-direction: column-reverse;
		@media screen and (min-width: 767px) {
			flex-direction: row;
		}
	}
	.col-md-6 {
		text-align: center;
		color: white;
		a {
			color: white;
			text-decoration: underline;
		}
	}

	.col-md-6:first-child {
		@media screen and (min-width: 767px) {
			text-align: left;
		}
	}
	.col-md-6:last-child {
		@media screen and (min-width: 767px) {
			text-align: right;
		}
	}
}

#footer {
	.m1-description-footer {
		margin-top: 2rem;
		.subtitle-footer {
			font-size: 12px;
		}
		.kl-footer a,
		.kl-footer span.obfuscate {
			display: inline-block;
		}
		.content {
			font-size: 14px;
		}
		.content:last-child {
			br {
				display: none;
			}
		}
	}

	.block-categories {
		.kl-title-sidebar {
			display: none;
		}
		a {
			color: white;
			margin-bottom: 0;
		}
		.category-sub-menu {
			.material-icons.add,
			.material-icons.remove,
			.material-icons.arrow-right,
			.material-icons.arrow-down {
				color: white;
				font-size: 14px;
			}
		}
		.arrows {
			.material-icons.arrow-right {
				display: none;
			}
			.material-icons.arrow-down {
				display: inline-block;
			}
		}
		.arrows.collapsed {
			.material-icons.arrow-down {
				display: none;
			}
			.material-icons.arrow-right {
				display: inline-block;
			}
		}
	}
	.email_subscription {
		.wrapper-form {
			display: flex;
			margin-bottom: 1rem;
		}
		input[type="text"] {
			padding: 1rem;
			border: none;
			outline: none;
			flex: 0 1 100%;
			appearance: none;
			border-radius: 0;
		}
		button {
			border: none;
			appearance: none;
			background-color: white;
			outline: none;
			padding: 0 16px;
		}
	}

	.ps-social-follow {
		li {
			display: inline-block;
			margin-right: 18px;
		}
		.m1-facebook,
		.m1-twitter,
		.m1-youtube,
		.m1-instagram,
		.m1-pinterest,
		.flux-rss {
			> a {
				display: block;
				width: 25px;
				height: 25px;
				//background-color: #fff;
				background-size: contain;
				background-position: 50% 50%;
				background-repeat: no-repeat;
			}
		}
		.m1-instagram > a {
			background-image: url("../img/icons/instagram.svg");
		}
		.m1-pinterest > a {
			background-image: url("../img/icons/pinterest-brands.svg");
		}
		.m1-facebook > a {
			background-image: url("../img/icons/facebook.svg");
		}
		.m1-twitter > a {
			background-image: url("../img/icons/twitter.svg");
		}
		.m1-youtube > a {
			background-image: url("../img/icons/youtube.svg");
		}
		.flux-rss > a {
			background-image: url("../img/icons/flux-rss.svg");
		}
		.flux-rss {
			display: none;
		}
	}

	.categories {
		> li {
			> a {
				font-weight: 500;
			}
			> .subcategories {
				padding-left: 20px;
				margin-bottom: 2rem;
				> li {
					> a {
						font-weight: 200;
					}
				}
			}
		}
	}
}

#gdpr_consent {
	ul {
		display: flex;
		font-size: 14px;
		margin-bottom: 1rem;
		text-align: justify;
		align-items: center;
	}
	li:first-child {
		margin-right: 16px;
	}
	.kl-custom-chx {
		width: 20px;
		height: 20px;
		label {
			top: 0;
			left: 0;
			width: 20px;
			height: 20px;
		}
	}
}

#backtotop {
	width: 50px;
	height: 50px;
	background-color: #000;
	transition: background-color 300ms ease;
	border-radius: 50%;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	cursor: pointer;
	&:hover {
		background-color: $bleu;
	}
	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -60%) rotate(180deg);
		background: url("../img/dropdown-white.svg") no-repeat 50% 50% / contain;
		width: 20px;
		height: 20px;
		//@include icon("dropdown", 20px, #fff, #fff);
	}
}
