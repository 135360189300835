#cart .kl-main-content,
body#checkout #wrapper {
	background: $gris;
}
#cart .kl-main-content {
	padding-top: 0;
	h1 {
		font-size: 32px;
	}
	.product-line-info.product-price h5 {
		margin: 1rem 0;
	}
}

#cart #footer .kl-block-service.seopresta_reinsurance,
#checkout #footer .kl-block-service.seopresta_reinsurance {
	background-color: #fff;
}

.product-line-grid-body > .product-line-info > .label {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 1rem;
}

// checkout

#checkout {
	.nav-inline {
		@media screen and (min-width: 550px) {
			display: flex;
			align-items: center;
		}
		li:first-child {
			a {
				padding-left: 0;
			}
		}
	}
	.custom-radio {
		.radio-inline {
			display: inline-flex;
			align-items: center;
			margin-right: 16px;
		}
	}
}

.step-title.h3 {
	@media screen and (min-width: 768px) {
		display: flex;
		align-items: center;
	}
	.done {
		margin-right: 8px;
	}
	.step-edit.text-muted {
		margin-left: auto;
		font-size: 15px;
	}
}

body#checkout section.checkout-step {
	.radio-block {
		display: block;
	}
	.address-alias {
		margin-left: 8px;
	}
	.address-item.selected {
		padding-top: 1rem;
	}
	hr {
		margin-top: 0.625rem;
		margin-bottom: 0.625rem;
	}
	.address-footer {
		a {
			font-size: 14px;
			i {
				margin-right: 8px;
			}
		}
	}
}
.add-address {
	a {
		font-size: 14px;
	}
}


// delivery 
#js-delivery {
    span {
        margin-bottom: 0 !important;
    }
}