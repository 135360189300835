// global
.page-customer-account,
.page-my-account,
.page-addresses {
	.page-header {
		margin-bottom: 1rem;
		text-align: center;
		h1 {
			font-size: 30px;
			font-weight: 500;
			color: $primary-dark;
		}
	}
	.kl-main-content {
		> .container > .row {
			display: block;
		}
	}
}

.container_module-lgcomments-accountreviews {
	h1 {
		font-size: 30px;
		font-weight: 500;
		color: $primary-dark;
		text-align: center;
		margin-bottom: 2rem;
	}
}
.page-module-prodquestions-myquestions h3 {
	font-size: 30px;
	font-weight: 500;
	color: $primary-dark;
	text-align: center;
	margin-bottom: 2rem;
}

// authentification
.page-authentication #content {
	background: $beige;
	#login-form {
		.form-control-label {
			@media screen and (max-width: 768px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}

.form-control-label {
	@media screen and (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

// register
.register-form {
	a {
		font-weight: 500;
	}
}

// my account
.page-my-account #content .links a {
	i {
		color: white;
	}
	span.link-item {
		background: $primary-dark;
		color: white;
		transition: background-color 300ms ease;
		&:hover {
			background: $primary;
		}
	}

	&:hover {
		i {
			color: #e0d3b3;
		}
	}
}
#my-account {
	.page-footer {
		a {
			background-color: red;
			padding: 0.625rem 2rem;
			color: white;
            display: inline-block;
            margin-bottom: 2rem;
		}
	}
}

.page-customer-account,
.page-addresses {
	.page-footer {
		background-color: $primary-dark;
		text-align: center;
		color: white;
		padding-top: 1rem;
		padding-bottom: 1rem;
		a {
			display: inline-flex;
			align-items: center;
			color: white;
			transition: color 300ms ease;
			i,
			span {
				color: white;
			}

			&:hover {
				text-decoration: none;
				color: #e0d3b3;
				i,
				span {
					color: #e0d3b3;
				}
			}
		}
	}
}

// adresses
.page-addresses {
	.address-body {
		margin-bottom: 0;
		address {
			margin-bottom: 0;
		}
	}
	.address-footer,
	.addresses-footer {
		text-align: center;
		a {
			display: inline-flex;
			align-items: center;
			&:hover {
				text-decoration: none;
			}
			span {
				margin-top: 0;
			}
		}
	}
}
